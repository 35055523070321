<template>
  <div class="auth-wrapper auth-v1 px-2 scl-login-page">
    <div class="auth-inner py-5">
      <!-- Login-->
      <b-col md="4" />
      <b-col class="p-0">
        <b-card :class="getLoginCardCss">
          <!-- Brand logo-->
          <b-link class="brand-logo scl-logo mb-2">
            <b-img
              ref="previewEl"
              rounded
              src="@/assets/images/logo/logo.png"
              height="80"
            />
          </b-link>
          <!-- /Brand logo-->
          <b-card-title
            class="mb-1 font-weight-bold text-center"
            title-tag="h4"
          >
            Welcome to the Operative portal
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            If this is the first time you've logged in, please enter your email and select "Reset Password".
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="johndoe@gmail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link @click="confirmReset">
                    <small>Reset Password</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-badge
                  v-if="showError"
                  variant="light-danger"
                >
                  Username/password invalid
                </b-badge>
              </b-form-group>
              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Login
              </b-button>
              <b-form-group>
                <label class="mt-2 text-primary">If you want to see remittances prior to April 2021 please use the <a
                  id="scl-old-portal-link"
                  href="https://docs.supplyconstruction.co.uk:444/login"
                  target="scl-document-centre"
                >
                  SCL Document Centre
                </a>
                </label>
              </b-form-group>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col md="4" />
    <!-- /Login-->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCol, BCard, BLink, BFormGroup, BFormInput, BInputGroup, BBadge, BCardText, BCardTitle, BForm, BButton, VBTooltip, BImg,
} from 'bootstrap-vue'
import jwt from '@/auth/jwt/sclUseJwt'
import axios from 'axios'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import swal from 'sweetalert2'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCol,
    BLink,
    BImg,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BBadge,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showError: false,
      isMobile: false,
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    getLoginCardCss() {
      /* eslint-disable */
      if (this.isMobile) {
          return 'scl-mobile-login-card'
        }
      return 'scl-desktop-login-card'
      /* eslint-enable */
    },
  },
  created() {
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
  methods: {
    showMessage(message, inError) {
      swal.fire({
        title: inError ? 'Error' : 'Success!',
        text: message,
        icon: inError ? 'error' : 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    confirmReset() {
      if (this.username === '') {
        this.showMessage('Please enter an email address', true)
        return
      }

      this.forgotPassword()

      // this.$bvModal
      //   .msgBoxConfirm(`An email will be sent to '${this.username}' with instructions on how to reset your password.`, {
      //     title: 'Warning',
      //     titleVariant: 'Warning',
      //     size: 'sm',
      //     okVariant: 'warning',
      //     okTitle: 'OK',
      //     cancelTitle: 'Cancel',
      //     cancelVariant: 'outline-warning',
      //     modalClass: 'modal-warning',
      //     hideHeaderClose: true,
      //     centered: true,
      //   })
      //   .then(value => {
      //     if (value !== true) return

      //     this.forgotPassword()
      //   })
    },
    forgotPassword() {
      const data = JSON.stringify({ email: this.username.toUpperCase() })
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      axios.post(`${this.$siteUrl}password_reset/`, data, config)
        .then(response => {
          if (response.data.status === 'OK') {
            this.showMessage('A reset link has been sent to your email address', false)
          }
        })
        .catch(e => {
          if (e.response === undefined) {
            this.showMessage('Unable to connect to server at this time, please try again later', true)
          } else {
            this.showMessage(e.response.data.email, true)
          }
        })
    },
    login() {
      this.showError = false
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$store.commit('app/clearToken')
          try {
            axios.post(`${this.$siteUrl}token/`, { username: this.username.toUpperCase(), password: this.password })
              .then(response => {
                jwt.setToken(response.data.access)
                jwt.setRefreshToken(response.data.refresh)
                this.$store.commit('app/setLoggedIn')
                this.$store.commit('app/setUsername', this.username.toUpperCase())
                this.$store.commit('app/setAccessExpiry', response.data.accessexp)
                this.$store.commit('app/setRefreshExpiry', response.data.refreshexp)
                this.$store.dispatch('app/getCurrentOperative', { email: this.username.toUpperCase() })
                this.$store.dispatch('app/setAccessTokenTimer')
                this.$store.dispatch('app/setRefreshTokenTimer')
              })
              .catch(e => {
                console.error(e)
                this.showError = true
              })
          } catch (error) {
            this.showError = true
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.scl-login-page {
  background: linear-gradient(180deg, #06B39A 1.88%, #017E6C 59.09%, #004A4D 95.76%);
  height: 120% !important;
}
.scl-desktop-login-card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
  width: 400px;
}
.scl-mobile-login-card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
.scl-logo {
  display: flex;
  justify-content: center;
}

#scl-old-portal-link {
  text-decoration: underline;
}
</style>
